

















































.courseCenter {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  .shell_1 {
    width: 1168px;
    margin: auto;
    .p_1 {
      text-align: center;
      color: #3f4652;
      font-size: 26px;
      margin-bottom: 40px;
      margin-top: 60px;
    }
    .shell_1_1 {
      width: 49%;
      height: 245px;
      float: left;
      margin: 0 2% 20px 0;
      border-radius: 6px;
      position: relative;
      div {
        width: 100%;
        height: 80px;
        line-height: 80px;
        padding-left: 20px;
        background: rgba(0, 0, 0, 0.4);
        font-size: 20px;
        color: #fff;
        position: absolute;
        bottom: 0;
      }
      &:nth-child(2n + 1) {
        margin-right: 0 !important;
      }
    }
    .b_1 {
      background: url("../../assets/loginIndex/integratedMarketing_1.png")
        no-repeat;
      background-size: 100% 100%;
    }
    .b_2 {
      background: url("../../assets/loginIndex/integratedMarketing_2.png")
        no-repeat;
      background-size: 100% 100%;
    }
    .b_3 {
      background: url("../../assets/loginIndex/integratedMarketing_3.png")
        no-repeat;
      background-size: 100% 100%;
    }
    .b_4 {
      background: url("../../assets/loginIndex/integratedMarketing_4.png")
        no-repeat;
      background-size: 100% 100%;
    }
  }
  .shell_2 {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: -4px;
    .p_1 {
      position: absolute;
      left: 50%;
      top: 8%;
      color: #fff;
      font-size: 26px;
      margin-left: -212px;
    }
    img {
      width: 100%;
    }
  }
}
